











































































import "weui";
import Vue from "vue";
import { Toast, Picker, Popup } from "vant";

enum BindFrom {
  Scan,
  Input
}

export default Vue.extend({
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },

  data() {
    return {
      qrCode: "",
      businessId: 0,
      businessName: "解除绑定",
      bindType: 1,
      showLog: localStorage.getItem("showLog") || false,
      businessPicker: {
        list: [
          {
            value: 0,
            label: "解除绑定"
          }
        ],
        isLoaded: false,
        show: false
      },
      BindFrom: BindFrom
    };
  },
  created: function() {
    this.$readyWx(this.$axios, ["scanQRCode"]);
    if (localStorage.myCacheShowReplace > 0) {
      this.bindType = 1;
    } else {
      this.bindType = 2;
    }
  },
  methods: {
    bind(from: BindFrom) {
      if (!this.qrCode) {
        Toast.fail("请输入正确的二维数字编号");
        return;
      }
      let url;
      switch (this.bindType) {
        case 1:
          url = "/api/manage/ebike/bindBusiness";
          break;
        case 2:
          url = "/api/manage/cabinet/bindBusiness";
          break;
        case 3:
          url = "/api/manage/battery/bindBusiness?gen=1";
          break;
        case 4:
          url = "/api/manage/battery/bindBusiness?gen=2";
          break;
        default:
          Toast.fail("请选择绑定类型");
          return;
      }
      if (this.bindType == 3 || this.bindType == 4) {
        if (Number(this.qrCode) > Math.pow(10, 9)) {
          Toast.fail("电池格式异常");
          return;
        }
      }
      this.$axios.post(url, { qrCode: this.qrCode, businessId: this.businessId }).then(() => {
        Toast.success({
          message: "操作成功",
          duration: 1500,
          onClose: () => {
            if (from == BindFrom.Scan) {
              this.scanQrCode();
            }
          }
        });
      });
    },
    pickBusiness() {
      if (!this.businessPicker.isLoaded) {
        this.$axios.post("/api/manage/getBusinessListForPick", {}).then(res => {
          const e = res.data;
          for (let i = 0; i < e.data.length; i++) {
            const listItem = e.data[i];
            this.businessPicker.list.push({
              value: listItem.id,
              label: listItem.name
            });
          }
          this.businessPicker.isLoaded = true;
          this.showBusinessPicker();
        });
      } else {
        this.showBusinessPicker();
      }
    },
    onConfirmPickBusiness(value: { value: number; label: string }) {
      this.businessId = value.value;
      this.businessName = value.label;
      this.businessPicker.show = false;
    },
    showBusinessPicker: function() {
      this.businessPicker.show = true;
    },
    scanQrCode() {
      this.$scanCode((c: string) => {
        if (c.split("c=").length === 2) {
          c = c.split("c=")[1];
        }
        this.qrCode = c;
        this.bind(BindFrom.Scan);
      });
    }
  }
});
